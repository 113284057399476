import React, { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import facebook from '../components/images/facebook2-white.svg';
// import twitter from '../components/images/twitter-white.svg';
import whitelogo from "../components/images/autoswiperai-logo-white.png";
import crown from "../components/images/crown.png";
import lightning from "../components/images/ligh.png";
import list from "../components/images/List.png";
import price from "../components/images/Price.png";
import LoginModal from "./LoginModal";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import tick from "./images/tick-icon.svg";

export default function Footer({
  loggedInId,
  setLoggedInId,
  load,
  isPaymentDone,
  setIsPaymentDone,
  paymentPopup,
  setPaymentPopup,
  showInst,
  setShowInst,
  showSub,
  setShowSub,
  showPaymentModal,
  setShowPaymentModal,
}) {
  const d = new Date();
  let year = d.getFullYear();

  const todayDate = new Date();
  const [showCancelModal, setShowCancelModal] = useState(false);

  const [subStatus, setSubStatus] = useState(false);
  const [subValidTill, setSubValidTill] = useState(false);
  const [cancelSubDiv, setCancelSubDiv] = useState(false);
  const [cancelButton, setCancelButton] = useState(true);
  const [resubButton, setReSubButton] = useState(false);
  const [subDetails, setSubDetails] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseCancel = () => setShowCancelModal(false);

  const HandleModel = () => {
    if (!loggedInId) {
      handleShow();
    } else {
      if (!isPaymentDone) {
        showPaymentModalHandler();
      } else if (showSub) {
        setShowCancelModal(true);
      }
    }
  };
  const showPaymentModalHandler = () => {
    handleShow();
    setShowPaymentModal(true);
  };

  const cancelSubFuncSubmit = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/cancel-subscription`,
        {
          customer_id: localStorage.getItem("customer_id").toString(),
        },
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((value) => {
        // console.log(value);
        if (value.data.status) {
          toast.success(value.data.msg);
          // setShowCancelModal(false)
          setShowSub(true);
          checkLoggedInStatus();
          setSubDetails(true);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    checkLoggedInStatus();
  }, []);

  const checkLoggedInStatus = async () => {
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/check-payment-status`,
          {
            customer_id: localStorage.getItem("customer_id")
              ? localStorage.getItem("customer_id").toString()
              : "",
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          var dateObj = new Date(value.data.current_period_end * 1000);
          var date =
            dateObj.getDate() +
            "/" +
            (dateObj.getMonth() + 1) +
            "/" +
            dateObj.getFullYear();
          if (value.data.subscription == "active") {
            setIsPaymentDone(true);
            setShowSub(true);
            localStorage.setItem("payment", true);
            setSubStatus(true);
            setSubValidTill(date);
            setShowInst(true);
            setPaymentPopup(false);
            setShow(false);
          } else if (value.data.subscription == "canceled") {
            if (value.data.current_period_end) {
              if (dateObj > todayDate) {
                setReSubButton(true);
                setCancelButton(false);
                setCancelSubDiv(false);
                setSubDetails(true);
                setIsPaymentDone(true);
                setShowSub(true);
                setSubValidTill(date);
                setSubStatus(true);
                setShowInst(true);
              } else {
                setSubStatus(false);
              }
            }
          }
          console.log("value", value);
        });
    } catch (err) {
      console.log(err);
      if (err.request) {
        if (err.request.status == 401) {
          setLoggedInId("");
          setPaymentPopup("");
          localStorage.removeItem("token");
          localStorage.removeItem("customer_id");
          localStorage.removeItem("payment");
          localStorage.removeItem("number");
        }
      }
    }
    var token = localStorage.getItem("token");
    if (localStorage.getItem("customer_id") === null) {
      console.log("tokennnnnnnnnnn", token);
      localStorage.removeItem("token");
    }
  };

  return (
    <>
      <section className="subscription_section pb-5">
        <div className="subscription_section_div">
          <div className="d-lg-flex">
            <Col className="sec-6-cols" id="sec-6-col1" lg={6} md={12} sm={12}>
              {/* <div className=" subs_div___">Get swiping with</div>
							<div className="subs_div___">AutoSwiper.Ai now</div> */}
              <div className="subs_div___">
                Get swiping with AutoSwiper.Ai now
              </div>
              <small className="subs_small">
                Stop wasting time swiping, and start picking from Ai crafted
                matches fitting your exact type.
              </small>
            </Col>
            <Col className="sec-6-cols" id="sec-6-col2" lg={6} md={12} sm={12}>
              <img src={crown} alt="Crown Design" className="sec-6-crown" />
              <div className="sec-6-div-2">
                <img
                  src={lightning}
                  alt="Lightning Icon"
                  className="sec-6-img"
                />
                <div className="sec-6-col-2-div text-dark fw-bold">
                  SUBSCRIPTIONS
                </div>
                <div className="sec-6-col-2-div1 text-dark">THE BEST DEAL</div>
                <div className="mt-3">
                  {/* <img src={price} alt="Price" /> */}
                  <div>
                    <span className="price-span">$</span>
                    <span className="price">4.99</span>
                    <span className="price-span">/mo</span>
                  </div>
                </div>
                <div className="sec-6-div">
                  {/* <img src={list} alt="Listing" /> */}
                  <div className="sec-6-points-div">
                    <img
                      src={tick}
                      alt="tick=icon"
                      className="sec-6-tick-icon"
                    />
                    <div className="sec-6-div-text">AutoSwiper</div>
                  </div>
                  <div className="sec-6-points-div">
                    <img
                      src={tick}
                      alt="tick=icon"
                      className="sec-6-tick-icon"
                    />
                    <div className="sec-6-div-text">All Filters</div>
                  </div>
                  <div className="sec-6-points-div">
                    <img
                      src={tick}
                      alt="tick=icon"
                      className="sec-6-tick-icon"
                    />
                    <div className="sec-6-div-text">RIZZ MODE</div>
                  </div>
                  <div className="sec-6-points-div">
                    <img
                      src={tick}
                      alt="tick=icon"
                      className="sec-6-tick-icon"
                    />
                    <div className="sec-6-div-text">Set Preferences</div>
                  </div>
                </div>
                {!loggedInId ? (
                  <Button
                    className="sec-6-btn"
                    id="sec_btn"
                    variant=""
                    onClick={() => handleShow()}
                  >
                    Get Started
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </div>
        </div>
      </section>
      <div className="footer-copyright py-3">
        <p className="text-center mb-0 footer-copyright-inside">
          Support:-
          <a
            className="text-light"
            style={{ textDecoration: "none" }}
            target="_blank"
            href="mailto:autoswiperai@gmail.com"
          >
            autoswiperai@gmail.com
          </a>
        </p>
        <p className="pt-2 text-center footer-copyright-inside">
          &copy;Copyright All Rights Reserved. AutoSwiper.Ai 2023
        </p>
      </div>
      <LoginModal
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        setLoggedInId={setLoggedInId}
        loggedInId={loggedInId}
        showPaymentModal={showPaymentModal}
        load={load}
        setIsPaymentDone={setIsPaymentDone}
        setPaymentPopup={setPaymentPopup}
        paymentPopup={paymentPopup}
        checkLoggedInStatus={checkLoggedInStatus}
      />
      <Modal
        show={showCancelModal}
        onHide={handleCloseCancel}
        centered
        className="cancel-modal text-light"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-light">Subscription Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-light">
            Subscription Status: {subStatus ? "True" : "False"}
          </div>
          <div className="text-light">
            Subscription Valid Till: {subValidTill}
          </div>
          {cancelButton ? (
            <Button
              variant="danger"
              className="mt-3 Cancel_Subscription"
              onClick={() => setCancelSubDiv(!cancelSubDiv)}
            >
              Cancel Subscription
            </Button>
          ) : (
            ""
          )}
          {cancelSubDiv ? (
            <div className="mt-2 card p-3 text-light">
              <div className="mb-2 text-light">
                Are you sure you want to cancel your subscription?
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  variant="secondary"
                  className="me-3"
                  onClick={() => setCancelSubDiv(!cancelSubDiv)}
                >
                  No
                </Button>
                <Button variant="danger" onClick={() => cancelSubFuncSubmit()}>
                  Yes
                </Button>
              </div>
            </div>
          ) : (
            ""
          )}
          {subDetails ? (
            <div className="pt-2 text-light">
              Your subscription plan has been cancelled
              <div>
                You can keep using your account until your next payment was due
              </div>
            </div>
          ) : (
            ""
          )}
          {resubButton ? (
            <div className="text-center">
              <Button
                variant=""
                className="resub-button mt-3"
                onClick={() => showPaymentModalHandler()}
              >
                Resubscribe
              </Button>
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
