import React, { useState } from 'react';
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import StripeContainer from './StripeContainer';

function Example() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow} className='mx-3'>
        Payment
      </Button> */}

      <Modal className='swiper-modal' show={show} onHide={handleClose} size='lg' aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton className='payment-heading'>
        </Modal.Header>
        <Modal.Body className='p-4'>
          <StripeContainer />
        </Modal.Body>

      </Modal>
    </>
  );
}
export default Example