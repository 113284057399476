import React, { useState, useEffect, useRef } from "react";
// import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Form, Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "react-phone-number-input/style.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-phone-number-input/style.css";
import OTPInput from "otp-input-react";
import pencil from "./images/pencil-icon.png";

const OtpVerifyOtp = ({
  editFunction,
  otp,
  setOtp,
  counter,
  hideResend,
  resendCode,
  error,
  number,
}) => {
  return (
    <div>
      <Container>
        <Row>
          <Col lg={12} md={12} className="pass-otp">
            <div className="swiper-heading2">
              <Modal.Title>
                <span>Almost Done here!</span>
                <div className="otp-text">
                  Please enter the verification code sent to {number}{" "}
                  <a
                    onClick={(e) => {
                      editFunction(e);
                    }}
                  >
                    <img
                      src={pencil}
                      alt="pencil"
                      width={14}
                      style={{ filter: "invert(1)", cursor: "pointer" }}
                    />
                  </a>
                </div>
              </Modal.Title>
            </div>
            <div className="text-center mt-2 enter-otp">
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={9} md={12} className="m-auto">
            <span className="msg"></span>
            <Form.Group
              className="mb-3 mt-4 hide-form"
              controlId="formBasicOtp"
            >
              <OTPInput
                value={otp}
                type="otp"
                otpType="number"
                OTPLength={6}
                required={true}
                // placeholder="Enter OTP"
                onChange={setOtp}
                // onChange={(e) => setOtp(e.target.value)}
                disabled={false}
                autoFocus
              />
            </Form.Group>
            <h6 className="otp-status">
              {" "}
              {counter ? <span>Resend Code in 00:{counter}</span> : ""}
              {hideResend === true ? (
                ""
              ) : (
                <a className="ps-1 fw-bold" onClick={(e) => resendCode(e)}>
                  Resend Code
                </a>
              )}
            </h6>
            <div className="Otp-btn text-center mb-4">
              <Button id="otp-modal-btn" type="submit" variant="">
                Next
              </Button>
            </div>
            <div
              id="recaptcha-container2"
              className="ml-2  mb-5 recaptcha-container"
            ></div>
          </Col>
          <Col md={12} className="error p-0">
            {error && <Alert variant="danger">{error}</Alert>}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OtpVerifyOtp;
