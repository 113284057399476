import React, { useState, useMemo, useRef, useEffect } from "react";
import {
  useElements,
  useStripe,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
  options,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { Form } from "react-bootstrap";
// import Card from 'react-bootstrap/Card';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import mastercard from "./images/mastercard-logo.png";
import amex from "./images/amex-logo.png";
import visa from "./images/visa-logo.png";
import diners_club from "./images/diners_club.png";
import discover from "./images/discover-logo.png";
import jcb from "./images/jcb_logo.png";
import union_pay from "./images/union_pay.png";
import bankingCard from "./images/credit_card.png";
import Modal from "react-bootstrap/Modal";
import checkmark from "./images/checkmark.png";
import ClipLoader from "react-spinners/ClipLoader";
import toast, { Toaster } from "react-hot-toast";
import { hostname } from "./hostname";
import GoogleApplePay from "./GoogleApplePay";

export default function PaymentForm(props) {
  const DARK_CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#ff5864",
        color: "black",
        "::placeholder": {
          color: "grey",
        },
      },
    },
  };

  const paymentLoader = useRef();
  const [imgSrc, setImgSrc] = useState();
  const [billingDetails, setBillingDetails] = useState({
    name: "",
  });
  const [nameError, setNameError] = useState(false);
  const [carNumError, setCardNumError] = useState(false);
  const [expDateError, setExpDateError] = useState(false);
  const [cvcError, setCvcError] = useState(false);
  const [returnUrl, setReturnUrl] = useState("");
  const [intentId, setIntentId] = useState("");

  const imageChange = (e) => {
    console.log(e);
    if (e.empty === false) {
      setCardNumError(false);
    }
    if (e.brand === "visa") {
      setImgSrc(visa);
    } else if (e.brand === "mastercard") {
      setImgSrc(mastercard);
    } else if (e.brand === "amex") {
      setImgSrc(amex);
    } else if (e.brand === "diners") {
      setImgSrc(diners_club);
    } else if (e.brand === "discover") {
      setImgSrc(discover);
    } else if (e.brand === "jcb") {
      setImgSrc(jcb);
    } else if (e.brand === "unionpay") {
      setImgSrc(union_pay);
    }
  };

  const Field = ({
    label,
    id,
    name,
    type,
    placeholder,
    required,
    autoComplete,
    value,
    onChange,
  }) => (
    <div className="FormRow">
      <label htmlFor={id} className="FormRowLabel">
        {label}
      </label>{" "}
      <br />
      <input
        className="FormRowInput"
        id={id}
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
        name={name}
      />
    </div>
  );
  const useOptions = () => {
    const options = useMemo(() => ({
      style: {
        base: {
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          fontSize: "20px",
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }));

    return options;
  };
  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        // iconColor: "#c4f0ff",
        color: "#fff",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        // ":-webkit-autofill": { color: "#fce883" },
        // "::placeholder": { color: "#87bbfd" }
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const pay = async (e) => {
    e.preventDefault();
    const dat = stripe
      .confirmCardPayment(
        intentId,
        {
          payment_method: { card: elements.getElement(CardNumberElement) },
          return_url: returnUrl,
        },
        // Disable the default next action handling.
        { handleActions: false }
      )
      .then(function (result) {
        // Handle result.error or result.paymentIntent
        // More details in Step 2.
        console.log(result);
        if (result.paymentIntent.status === "requires_action") {
          window.open(
            result.paymentIntent.next_action.redirect_to_url.url,
            "_blank",
            "popup=yes"
          );
          // window.setRequestHeader("Content-type","application/x-www-form-urlencoded")
        }
        // if(result.error)
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  function on3DSComplete() {
    // Hide the 3DS UI

    // Check the PaymentIntent
    stripe.retrievePaymentIntent(intentId).then(function (result) {
      if (result.error) {
        // PaymentIntent client secret was invalid
      } else {
        if (result.paymentIntent.status === "succeeded") {
          // Show your customer that the payment has succeeded
        } else if (result.paymentIntent.status === "requires_payment_method") {
          // Authentication failed, prompt the customer to enter another payment method
        }
      }
    });
  }

  function redditPurchasettq(paymentMethodId) {
    window.rdt("track", "Purchase", {
      currency: "USD",
      itemCount: 1,
      transactionId: paymentMethodId,
      value: 4.99,
      products: [
        {
          id: paymentMethodId,
          name: "Auto Swiper Monthly",
          category: "product category 1",
        },
      ],
    });
  }

  window.addEventListener(
    "message",
    function (ev) {
      if (ev.data === "3DS-authentication-complete") {
        on3DSComplete();
      }
    },
    false
  );
  var RunCallbackFunction = function () {}; //reference holder only
  function myFunc() {
    alert("I'm a function in the parent window");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const mobile_number = props.mobile_number
      ? props.mobile_number
      : JSON.parse(localStorage.getItem("number"));

    if (billingDetails.name === "") {
      setNameError(true);
    }

    paymentLoader.current.style.display = "flex";

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: billingDetails,
    });
    if (error) {
      toast.error(error.message);
      if (error.code === "incomplete_number") {
        setCardNumError(true);
      }
      if (error.code === "incomplete_expiry") {
        setExpDateError(true);
      }
      if (error.code === "incomplete_cvc") {
        setCvcError(true);
      }
    }

    if (nameError || carNumError || expDateError || cvcError) {
      paymentLoader.current.style.display = "none";
      return;
    }
    if (!error) {
      const { id } = paymentMethod;
      redditPurchasettq(id);
      completePaymentProcess(id);
    } else {
      paymentLoader.current.style.display = "none";
      console.log(error.message);
    }
  };

  const completePaymentProcess = async (paymentMethodId) => {
    try {
      // const { id } = paymentMethod
      // const intentId = localStorage.getItem("paymentIntentId");
      const mobile_number = props.mobile_number
        ? props.mobile_number
        : JSON.parse(localStorage.getItem("number"));
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/make-payment`,
        {
          id: paymentMethodId,
          mobile_number: mobile_number,
        },

        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      console.log(response);
      if (response.data.status === true) {
        if (response.data.payment_status == "succeeded") {
          paymentLoader.current.style.display = "none";
          setSuccess(true);
          if (response.data.msg === "Subscription Added") {
            localStorage.setItem("payment", true);
            props.setIsPaymentDone(true);
          }
          setTimeout(() => {
            document.location.reload();
          }, 3000);
        } else if (response.data.payment_status === "requires_action") {
          console.log("response.data.return_url", response.data.return_url);
          const result = await stripe.confirmCardPayment(
            response.data.client_secret,
            {
              payment_method: paymentMethodId,
              return_url: response.data.return_url,
            }
          );
          console.log("response.data.return_url", response.data.return_url);
          console.log(result);
          if (result.error) {
            paymentLoader.current.style.display = "none";
            toast.error(result.error.message);
          } else {
            if (result.paymentIntent.status === "succeeded") {
              const apiResponse = await axios.get(
                `${response.data.return_url}&payment_intent=${result.paymentIntent.id}`
              );
              paymentLoader.current.style.display = "none";
              if (apiResponse.data.status === true) {
                setSuccess(true);
                localStorage.setItem("payment", true);
                props.setIsPaymentDone(true);
                setTimeout(() => {
                  document.location.reload();
                }, 3000);
              } else {
                toast.error(result.data.msg);
              }
            }
          }
        }
      } else if (response.data.subscribed === true) {
        props.setIsPaymentDone(true);
        toast.success(response.data.msg);
        paymentLoader.current.style.display = "none";
        props.setPaymentPopup(false);
      } else {
        console.log(response.data.msg);
        paymentLoader.current.style.display = "none";
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.log("Error", error);
      paymentLoader.current.style.display = "none";
    }
  };

  const analticCall = () => {
    window.ttq.track("CompletePayment", { value: 4.99, currency: "USD" });
  };

  return (
    <>
      {!success ? (
        <div className="extension-subscription-payment-modal">
          <div
            className="ring-loader"
            ref={paymentLoader}
            style={{ display: "none", placeContent: "center" }}
          >
            <ClipLoader size={250} color={"#fff"} />
          </div>

          <Row id="paymentModal" className="payment-modal">
            <Row style={{ display: "contents" }}>
              <Col
                lg={12}
                md={12}
                sm={12}
                style={{ paddingRight: "0px !important" }}
              >
                <div className="subscription-heading mb-3">
                  <h3 className="payment-modal-heading">
                    One step to get straight A's
                  </h3>
                  <div className="payment-modal-sub-heading">
                    Your one step away for A’s
                  </div>
                </div>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </Col>
            </Row>
            <Form onSubmit={handleSubmit}>
              <Col className="Card">
                <div className="pricing">
                  <span className="payment-dollar-sign">$</span>
                  <span className="payment-dollar-price">4.99</span>
                  <span className="payment-dollar-sign">/mo</span>
                </div>

                <p className="payment-sub-heading">
                  Stop wasting time swiping, and start picking from Ai crafted
                  matches fitting your exact type.
                </p>
              </Col>
              <Col>
                <fieldset className="FormGroup mt-2">
                  <div className="FormRow mb-3">
                    <Form.Group className="" controlId="formBasicEmail">
                      <Form.Label>Name</Form.Label>
                      <input
                        type="text"
                        placeholder="Type here"
                        id="name"
                        name="name"
                        value={billingDetails.name}
                        onChange={(e) => {
                          setBillingDetails({
                            ...billingDetails,
                            name: e.target.value,
                          });
                          if (e.target.value !== "") {
                            setNameError(false);
                          }
                        }}
                      />
                    </Form.Group>
                    {nameError === true ? (
                      <p className="payment-form-error">Name is required</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="FormRow">
                    <div className="mb-3">
                      <label>
                        Card number
                        <CardNumberElement
                          className="text-light mycard"
                          name="card-number"
                          options={DARK_CARD_OPTIONS}
                          onChange={(event) => {
                            console.log("CardNumberElement [change]", event);
                            imageChange(event);
                          }}
                        />
                        {carNumError === true ? (
                          <p className="payment-form-error">
                            Card number is empty or incomplete
                          </p>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                    <div className="d-flex">
                      <label
                        style={{ width: "100%" }}
                        className="mb-3 text-light"
                      >
                        Expiration date
                        <CardExpiryElement
                          className="expiry-date text-light"
                          name="expiry-date"
                          options={DARK_CARD_OPTIONS}
                          onChange={(event) => {
                            if (event.empty === false) {
                              setExpDateError(false);
                            }
                          }}
                        />
                        {expDateError === true ? (
                          <p className="payment-form-error">
                            Expiration Date is empty or incorrect
                          </p>
                        ) : (
                          ""
                        )}
                      </label>
                      <label className="text-light" style={{ width: "100%" }}>
                        CVC
                        <CardCvcElement
                          className=" cvc-number text-light"
                          placeholder="###"
                          name="cvc-number"
                          options={DARK_CARD_OPTIONS}
                          onChange={(event) => {
                            if (event.empty === false) {
                              setCvcError(false);
                            }
                          }}
                        />
                        {cvcError === true ? (
                          <p className="payment-form-error">CVC is required</p>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>
                </fieldset>

                {/* {paymentRequest && <PaymentRequestButtonElement options={{paymentRequest}} />} */}
                <Col className="payment-stripe">
                  Powered By <span className="payment-stripe-span">Stripe</span>
                </Col>
              </Col>
              <Col className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="pay-btn"
                  onClick={() => analticCall()}
                >
                  Next
                </button>
              </Col>
              <div className="google-apple-pay-btn">
                <GoogleApplePay
                  completePaymentProcess={completePaymentProcess}
                />
              </div>
            </Form>
          </Row>
        </div>
      ) : (
        <div>
          <div className="p-4 text-center">
            <div>
              {/* <img src={checkmark} className="checkmark-img" /> */}
              <div className="wrapper">
                <svg
                  className="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  {" "}
                  <circle
                    class="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />{" "}
                  <path
                    class="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
            </div>
            <div className="success-text">Payment Successful</div>
          </div>
        </div>
      )}
    </>
  );
}
