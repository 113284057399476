import React, { useState, useRef, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import homepic from "../components/images/home-t.png";
import BlueCheck from "../components/images/Checkmarkblue.png";
import LoginModal from "./LoginModal";
import social from "../components/images/social.png";
import PhoneInput from "react-phone-number-input";
import Button from "react-bootstrap/Button";
import MainExtension from "../components/images/MainExtension.png";

import axios from "axios";
const STATUS = {
  STARTED: "Started",
  STOPPED: "Stopped",
};

const INITIAL_COUNT = 14400;
function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

// https://stackoverflow.com/a/2998874/1673761
const twoDigits = (num) => String(num).padStart(2, "0");

export default function SectionOne({
  loggedInId,
  setLoggedInId,
  load,
  isPaymentDone,
  setIsPaymentDone,
  paymentPopup,
  setPaymentPopup,
  showInst,
  setShowInst,
  showSub,
  setShowSub,
}) {
  useEffect(() => {
    setStatus(STATUS.STARTED);
  }, []);
  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT);
  const [status, setStatus] = useState(STATUS.STOPPED);

  const todayDate = new Date();

  const [subStatus, setSubStatus] = useState(false);
  const [subValidTill, setSubValidTill] = useState(false);
  const [cancelSubDiv, setCancelSubDiv] = useState(false);
  const [cancelButton, setCancelButton] = useState(true);
  const [resubButton, setReSubButton] = useState(false);
  const [subDetails, setSubDetails] = useState(false);

  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;
  const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60;

  const handleStart = () => {
    setStatus(STATUS.STARTED);
  };
  const [number, setNumber] = useState("");

  const handleStop = () => {
    setStatus(STATUS.STOPPED);
  };
  const handleReset = () => {
    setStatus(STATUS.STOPPED);
    setSecondsRemaining(INITIAL_COUNT);
  };
  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setStatus(STATUS.STOPPED);
      }
    },
    status === STATUS.STARTED ? 1000 : null
    // passing null stops the interval
  );
  const [show, setShow] = useState(false);

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkLoggedInStatus = async () => {
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/check-payment-status`,
          {
            customer_id: localStorage.getItem("customer_id")
              ? localStorage.getItem("customer_id").toString()
              : "",
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          var dateObj = new Date(value.data.current_period_end * 1000);
          var date =
            dateObj.getDate() +
            "/" +
            (dateObj.getMonth() + 1) +
            "/" +
            dateObj.getFullYear();
          if (value.data.subscription == "active") {
            setIsPaymentDone(true);
            setShowSub(true);
            localStorage.setItem("payment", true);
            setSubStatus(true);
            setSubValidTill(date);
            setShowInst(true);
            setPaymentPopup(false);
            setShow(false);
          } else if (value.data.subscription == "canceled") {
            if (value.data.current_period_end) {
              if (dateObj > todayDate) {
                setReSubButton(true);
                setCancelButton(false);
                setCancelSubDiv(false);
                setSubDetails(true);
                setIsPaymentDone(true);
                setShowSub(true);
                setSubValidTill(date);
                setSubStatus(true);
                setShowInst(true);
              } else {
                setSubStatus(false);
              }
            }
          }
          console.log("value", value);
        });
    } catch (err) {
      console.log(err);
      if (err.request) {
        if (err.request.status == 401) {
          setLoggedInId("");
          setPaymentPopup("");
          localStorage.removeItem("token");
          localStorage.removeItem("customer_id");
          localStorage.removeItem("payment");
          localStorage.removeItem("number");
        }
      }
    }
    var token = localStorage.getItem("token");
    if (localStorage.getItem("customer_id") === null) {
      console.log("tokennnnnnnnnnn", token);
      localStorage.removeItem("token");
    }
  };

  return (
    <>
      <div className="sectionOne container-fluid">
        <Row>
          <Col className="sectionOneHeadingCol" lg={5} md={12} sm={12}>
            <div className="main_image">
              <img
                className="w-100 h-100 img-fluid"
                src={MainExtension}
                alt="MainExtension"
              />
            </div>
          </Col>

          {/* {loggedInId ? (
						<Col lg={6} md={12} sm={12} className="homepic">
							<div className="swiper-heading"></div>
							<div className="user-box">
								<div className="tinder-baseline">
									<h2>Welcome to the Ai family!</h2>
									<p className="text-center">
										Follow the steps below to get started
										and ace your assignments.
									</p>
								</div>
								<img
									src={social}
									alt=""
									style={{ width: "100%" }}
								/>
								
								<div className="border-left mt-2 inst-steps">
									<div
										className="align-items-center mb-4 mt-2"
										id="sectiononesteps"
									>
										<div className="text-center">
											<div className="introSectionone">
												<span id="introAi">
													Step One:{" "}
												</span>
												Click the link below to install the extension
												<br />
												<a target="_blank" href="https://chrome.google.com/webstore/detail/tinderai/cdmjmggfpejphjalkjojickofpfbgdpb?hl=en">
													chrome-web-store/AutoSwiper.Ai
												</a>
											</div>
										</div>
									</div>

									<div
										className="align-items-center mb-4"
										id="sectiononesteps"
									>
										<div className="text-center">
											<span id="introAi">Step Two: </span>{" "}
											Sign in using the phone number you
											just enrolled with
										</div>
									</div>
									<div
										className="align-items-center mb-4"
										id="sectiononesteps"
									>
										<div className="text-center">
											<span id="introAi">
												Step Three:{" "}
											</span>{" "}
											Start enjoying AutoSwiper.Ai
										</div>
									</div>
								</div>
							</div>
						</Col>
					) : ( */}
          <Col lg={7} md={12} sm={12} className="homepic">
            <div className="main_text__">
              Tinder Ai AutoSwiper, with Advanced Preferences
            </div>
            <div className="sub_main_text__">
              Stop wasting time swiping, and start picking from Ai crafted
              matches fitting your exact type.
            </div>
            {!loggedInId ? (
              <button
                onClick={() => handleShow()}
                className="Main_sub_button__ mt-4"
              >
                Get Started
              </button>
            ) : (
              ""
            )}
          </Col>
          {/* )} */}
        </Row>
      </div>

      <div className="sectionForCounter container-fluid">
        <div className="">
          <div className="row">
            <div className="col-4 analytics_counter text-center">
              1M+ <br />
              Users
            </div>
            <div className="col-4 analytics_counter text-center">
              100M+ <br />
              Swipes
            </div>
            <div className="col-4 analytics_counter text-center">
              10+ <br />
              Filters
            </div>
          </div>
        </div>
      </div>

      <LoginModal
        show={show}
        handleClose={handleClose}
        setLoggedInId={setLoggedInId}
        loggedInId={loggedInId}
        showPaymentModal={showPaymentModal}
        load={load}
        setIsPaymentDone={setIsPaymentDone}
        setPaymentPopup={setPaymentPopup}
        paymentPopup={paymentPopup}
        checkLoggedInStatus={checkLoggedInStatus}
      />
    </>
  );
}
