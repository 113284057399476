import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-phone-number-input/style.css";
import OTPInput from "otp-input-react";
import mylogo from "../components/images/mylogo.png";
import axios from "axios";
import pencil from "./images/pencil-icon.png";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../firebase";
import StripeContainerExt from "./StripeContainerExt";
import ClipLoader from "react-spinners/ClipLoader";
import social from "../components/images/social.png";

function Extension({
  setLoggedInId,
  paymentPopupProp,
  load,
  loggedInId,
  setIsPaymentDone,
}) {
  document.title = "Login";
  const [error, setError] = useState("");
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [number, setNumber] = useState("");
  const [result, setResult] = useState("");
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const [hideResend, setHideResend] = useState(true);
  const [counter, setCounter] = useState(1);

  useEffect(() => {
    window.rdt("track", "PageVisit", {
      products: [
        {
          name: "Extension",
        },
      ],
    });
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("token") !== "") {
      setPaymentPopup(true);
    } else {
      setPaymentPopup(false);
    }
    if (
      localStorage.getItem("payment") &&
      localStorage.getItem("payment") !== ""
    ) {
      setIsPaymentDone(true);
    }
    checkLoggedInStatus();
  }, []);

  const checkLoggedInStatus = async () => {
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/check-payment-status`,
          {
            customer_id: localStorage.getItem("customer_id").toString(),
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          const todayDate = new Date();

          var dateObj = new Date(value.data.current_period_end * 1000);

          if (value.data.subscription == "active") {
            setIsPaymentDone(true);
            localStorage.setItem("payment", true);
          } else if (value.data.subscription == "canceled") {
            if (dateObj > todayDate) {
            } else {
              setIsPaymentDone(false);
              localStorage.removeItem("payment");
            }
          }
          console.log("value", value);
        });
    } catch (err) {
      console.log(err);

      setLoggedInId("");
      setPaymentPopup("");
      localStorage.removeItem("token");
      localStorage.removeItem("customer_id");
      localStorage.removeItem("payment");
      localStorage.removeItem("number");
    }
    var token = localStorage.getItem("token");
    if (localStorage.getItem("customer_id") === null) {
      console.log("tokennnnnnnnnnn", token);
      localStorage.removeItem("token");
    }
  };

  useEffect(() => {
    if (counter === 0) {
      setHideResend(false);
    }
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const resendCode = (e) => {
    setHideResend(true);
    if (hideResend === false) {
      setCounter(59);
      resendOtp(e);
    }
  };

  function setUpRecaptcha(number) {
    if (Window.recaptchaVerifier) {
      return signInWithPhoneNumber(auth, number, Window.recaptchaVerifier);
    }
    Window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      { size: "invisible" },
      auth
    );

    return signInWithPhoneNumber(auth, number, Window.recaptchaVerifier);
  }
  function setUpRecaptcha2(number) {
    if (Window.recaptchaVerifier) {
      return signInWithPhoneNumber(auth, number, Window.recaptchaVerifier);
    }

    Window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container2",
      { size: "invisible" },
      auth
    );
    return signInWithPhoneNumber(auth, number, Window.recaptchaVerifier);
  }

  const editFunction = (e) => {
    e.preventDefault();
    setFlag(false);
  };

  const getOtp = async (e) => {
    e.preventDefault();
    console.log(number);
    setError("");
    if (number === "" || number === undefined)
      return setError("Please enter a valid phone number!");
    load.current.style.display = "flex";
    try {
      const response = await setUpRecaptcha(number);
      console.log(response);
      setResult(response);
      setFlag(true);
      setCounter(59);
      setHideResend(true);
      load.current.style.display = "none";
    } catch (err) {
      setError(err.message);
      load.current.style.display = "none";
    }
  };
  const resendOtp = async (e) => {
    console.log(number);
    setError("");

    if (number === "" || number === undefined)
      return setError("Please enter a valid phone number!");
    try {
      const response = await setUpRecaptcha2(number);
      console.log(response);
      setResult(response);
      setFlag(true);
      setCounter(59);
    } catch (err) {
      setError(err.message);
    }
  };
  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");

    if (otp === "" || otp === null) return setError("Please Enter a OTP");
    load.current.style.display = "flex";

    try {
      const res = await result.confirm(otp);
      console.log("confirm", res._tokenResponse.idToken);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/login-user`,
          {
            mobile_number: number,
            tokenid: res._tokenResponse.idToken,
          }
        );
        if (response.data.status == true) {
          console.log(response.data.msg);
          setLoggedInId(response.data.token);
          if (response.data.token && response.data.token !== "") {
            localStorage.setItem("token", JSON.stringify(response.data.token));
          } else {
            localStorage.removeItem("token");
          }
          localStorage.setItem("customer_id", response.data.user_id);
          localStorage.setItem("number", JSON.stringify(number));

          setPaymentPopup(true);
          checkLoggedInStatus();
        } else {
          console.log(response.data.msg);
        }
        load.current.style.display = "none";
        setPaymentPopup(true);
      } catch (error) {
        console.log("Error", error);
        load.current.style.display = "none";
      }
    } catch (err) {
      setError(err.message);
      load.current.style.display = "none";
    }
  };

  return (
    <>
      <div>
        {paymentPopup === true ? (
          <StripeContainerExt
            mobile_number={number}
            load={load}
            setIsPaymentDone={setIsPaymentDone}
          />
        ) : (
          <div>
            <div
              className="ring-loader"
              ref={load}
              style={{ display: "none", placeContent: "center" }}
            >
              <ClipLoader size={250} color={"#fff"} />
            </div>
            <Form
              className="extension-background"
              onSubmit={getOtp}
              style={{ display: !flag ? "block" : "none" }}
            >
              <Form.Group>
                {/* <Modal.Header closeButton></Modal.Header> */}
                <Modal.Body className="show-grid">
                  <Container>
                    <div className="sign-form">
                      <Col lg={12} md={12} className="for-app">
                        <div className="swiper-heading"></div>
                        <div className="user-box">
                          <div className="tinder-baseline">
                            <h3>Are you ready to start getting matches?</h3>
                            <p>
                              One million swipes and counting! Let’s get you set
                              up.
                            </p>
                          </div>
                          <img
                            src={social}
                            alt=""
                            className="login-popup-img"
                          />
                          <p className="loginModel-p">
                            Please enter your phone number below to signup and
                            get started
                          </p>
                          <PhoneInput
                            international
                            defaultCountry="US"
                            value={number}
                            onChange={setNumber}
                            placeholder="Enter Your Phone Number"
                          />
                        </div>
                        <div
                          id="recaptcha-container"
                          className="ml-2  mb-5 recaptcha-container"
                        ></div>
                        <Button
                          id="login-modal-btn"
                          type="submit"
                          className="payment-button"
                        >
                          Sign me up
                        </Button>
                        <div className="extenstion-information">
                          <p className="text-white">
                            Click on "Continue" to receive a verification code.
                            Message and data rates may apply. By clicking
                            continue, you agree to the <br />{" "}
                            <a
                              href={`${process.env.PUBLIC_URL}/terms-of-use`}
                              target="_blank"
                            >
                              Terms of Service
                            </a>{" "}
                            and{" "}
                            <a
                              href={`${process.env.PUBLIC_URL}/privacy-policy`}
                              target="_blank"
                            >
                              Privacy Policy
                            </a>
                            .
                          </p>
                        </div>
                      </Col>
                    </div>
                  </Container>
                  <Col md={12} className="error">
                    {error && <Alert variant="danger">{error}</Alert>}
                  </Col>
                </Modal.Body>
              </Form.Group>
            </Form>
            <Form
              className="hidden-form"
              onSubmit={verifyOtp}
              style={{ display: flag ? "block" : "none" }}
            >
              {/* <Container>
								<Row className="justify-content-center">
								
									<Col lg={6} md={12} className="pass-otp">
										<div className="swiper-heading3">
											<Modal.Title>
												
												&nbsp;<span>AutoSwiper.Ai</span>
											</Modal.Title>
										</div>
										<h4 className="text-center mt-2 enter-otp">
											Verify Phone Number
										</h4>
										<span className="msg">
											<h5 className="pt-2">
											Please enter the verification code sent to
											</h5>
											<h6 className="mobile-number text-center">
												{number}{" "}
												<a
													onClick={(e) => {
														editFunction(e);
													}}
												>
													<img
														src={pencil}
														alt="pencil"
														width={14}
													/>
												</a>
											</h6>
										</span>
										<Form.Group
											className="mb-3 mt-4 hide-form"
											controlId="formBasicOtp"
										>
											<OTPInput
												value={otp}
												type="otp"
												otpType="number"
												OTPLength={6}
												required={true}
												// placeholder="Enter OTP"
												onChange={setOtp}
												// onChange={(e) => setOtp(e.target.value)}
												disabled={false}
												autoFocus
											/>
										</Form.Group>
										<div className="Otp-btn text-center mt-4">
											<Button type="submit" variant="">
												NEXT
											</Button>
										</div>

										<h6 className="otp-status">
											Didn’t receive OTP?{" "}
											{counter ? (
												<span>
													Resend Code in 00:{counter}
												</span>
											) : (
												""
											)}
											{hideResend === true ? (
												""
											) : (
												<a
													className="ps-1"
													onClick={(e) =>
														resendCode(e)
													}
												>
													RESEND CODE
												</a>
											)}
										</h6>
										<Col md={12} className="error">
											{error && (
												<Alert variant="danger">
													{error}
												</Alert>
											)}
										</Col>
									
									</Col>
								</Row>
							</Container> */}
              <Container>
                <Row>
                  <Col lg={12} md={12} className="pass-otp">
                    <div className="swiper-heading2">
                      <Modal.Title>
                        <span>Almost Done here!</span>
                        <div className="otp-text">
                          Please enter the verification code sent to {number}{" "}
                          <a
                            onClick={(e) => {
                              editFunction(e);
                            }}
                          >
                            <img
                              src={pencil}
                              alt="pencil"
                              width={14}
                              style={{ filter: "invert(1)" }}
                            />
                          </a>
                        </div>
                      </Modal.Title>
                    </div>
                    <div className="text-center mt-2 enter-otp">
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={9} md={12} className="m-auto">
                    <span className="msg"></span>
                    <Form.Group
                      className="mb-3 mt-4 hide-form"
                      controlId="formBasicOtp"
                    >
                      <OTPInput
                        value={otp}
                        type="otp"
                        otpType="number"
                        OTPLength={6}
                        required={true}
                        // placeholder="Enter OTP"
                        onChange={setOtp}
                        // onChange={(e) => setOtp(e.target.value)}
                        disabled={false}
                        autoFocus
                      />
                    </Form.Group>
                    <h6 className="otp-status">
                      {" "}
                      {counter ? <span>Resend Code in 00:{counter}</span> : ""}
                      {hideResend === true ? (
                        ""
                      ) : (
                        <a className="ps-1" onClick={(e) => resendCode(e)}>
                          Resend Code in
                        </a>
                      )}
                    </h6>
                    <div className="Otp-btn text-center mb-4">
                      <Button id="otp-modal-btn" type="submit" variant="">
                        Next
                      </Button>
                    </div>
                    <div
                      id="recaptcha-container2"
                      className="ml-2  mb-5 recaptcha-container"
                    ></div>

                    <Col md={12} className="error">
                      {error && <Alert variant="danger">{error}</Alert>}
                    </Col>
                  </Col>
                </Row>
              </Container>

              <div
                id="recaptcha-container2"
                className="ml-2  mb-5 recaptcha-container"
              ></div>
            </Form>
          </div>
        )}
      </div>
    </>
  );
}

export default Extension;
