// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDL3TbTKsJLEhUjevMxSztio37ZEMIq5eo",
//   authDomain: "autoswiperai-d616e.firebaseapp.com",
//   projectId: "autoswiperai-d616e",
//   storageBucket: "autoswiperai-d616e.appspot.com",
//   messagingSenderId: "484205978311",
//   appId: "1:484205978311:web:e216da8d578b05e79314e2",
//   measurementId: "G-J7Q4ZJ2XCM",
// };

const firebaseConfig = {
  apiKey: "AIzaSyDPB2_jztx5u1SxfLF-T8zSVw9EB5yJhbY",
  authDomain: "autoswiperai-a3a62.firebaseapp.com",
  projectId: "autoswiperai-a3a62",
  storageBucket: "autoswiperai-a3a62.appspot.com",
  messagingSenderId: "830357600443",
  appId: "1:830357600443:web:250d8b87670f48802f119e",
  measurementId: "G-9JQ4SN94TB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
