import React from "react";
import Modal from "react-bootstrap/Modal";
import { BsFillPinFill } from "react-icons/bs";
import { IoExtensionPuzzle } from "react-icons/io5";
const InstructionPopup = ({ showInst, setShowInst }) => {
  const handleClose = () => setShowInst(false);

  const closePopup = () => {
    setShowInst(false);
  };
  return (
    <div>
      <Modal
        show={showInst}
        onHide={handleClose}
        centered
        className="inst-modal"
      >
        <Modal.Header closeButton id="introheader">
          <Modal.Title id="introtitile">
            Welcome to the <span id="introAi">Ai</span> revolution
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="border-left mt-2 inst-steps">
            <div
              className="align-items-center mb-4 mt-2"
              style={{ fontSize: "18px" }}
            >
              {/* <div className="">
                            <h5>Installation Guide using Chrome desktop web browser:</h5>
                        </div> */}
              <div>
                <div>
                  <span id="introAi">Step One: </span>
                  Click the link below to install the extension&nbsp;
                  <a
                    className="text-light"
                    id="introAia"
                    target="_blank"
                    href="https://chrome.google.com/webstore/detail/autoswiperai/elellmebpfipgkkgijamljmikdekhhfl"
                  >
                    chrome-web-store/AutoSwiper.Ai
                  </a>
                </div>
              </div>
            </div>

            <div
              className="d-flex align-items-center mb-4"
              style={{ fontSize: "20px" }}
            >
              {/* <div className="step-circle">
                            2
                        </div> */}
              <div>
                <span id="introAi">Step Two: </span> ‘Add’ and then ‘Pin’{" "}
                <BsFillPinFill className="mx-2" /> the AutoSwiper.Ai extension{" "}
                <IoExtensionPuzzle className="ms-2" />
              </div>
            </div>
            <div
              className="d-flex align-items-center mb-4"
              style={{ fontSize: "20px" }}
            >
              {/* <div className="step-circle-last">
                            3
                        </div> */}
              <div>
                <span id="introAi">Step Three: </span> Sign in using your phone
                #
              </div>
            </div>
            <div
              className="d-flex align-items-center mb-4"
              style={{ fontSize: "20px" }}
            >
              {/* <div className="step-circle-last">
                            3
                        </div> */}
              <div>
                <span id="introAi">Step Four: </span> Open AutoSwiper.Ai in a
                separate tab
              </div>
            </div>
            <div
              className="d-flex align-items-center mb-4"
              style={{ fontSize: "20px" }}
            >
              {/* <div className="step-circle-last">
                            3
                        </div> */}
              <div>
                <span id="introAi">Step Five: </span>Start auto-swiping!
              </div>
            </div>
            <div className="text-center">
              <button
                className="payment-done-popup"
                onClick={closePopup}
                style={{ width: "150px" }}
              >
                Done
              </button>
            </div>
            {/* // <div className='d-flex align-items-center mb-4'>
                    //     <div className="step-circle-last">
                    //         5
                    //     </div>
                    //      Start auto-swiping!

                    // </div>  */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InstructionPopup;
