import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PaymentForm from './PaymentForm';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';

const stripeTestPromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
export default function StripeContainer(props) {

    return (
        <>
                
                     
                        <Elements stripe={stripeTestPromise}>
                            <PaymentForm mobile_number={props.mobile_number} load={props.load} setShowPaymentModal={props.setShowPaymentModal} setIsPaymentDone={props.setIsPaymentDone} setPaymentPopup={props.setPaymentPopup}/>
                        </Elements>

        </>
    )
}