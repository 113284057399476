import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from "react-bootstrap/Button";
import LoginModal from "./LoginModal";
import Example from "./Payment";
import logo from "../components/images/mylogo.png";
// import {Router} from "react-router-dom"
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { hostname } from "./hostname";
import { Modal } from "react-bootstrap";
// import {logo} from "../components/images/mylogo.png"?
import { Navigate, useNavigate } from "react-router-dom";
const notify = () => toast.success("Logged out successfully.");

function TopBar({
  loggedInId,
  setLoggedInId,
  load,
  isPaymentDone,
  setIsPaymentDone,
  paymentPopup,
  setPaymentPopup,
  showInst,
  setShowInst,
  showSub,
  setShowSub,
}) {
  const [show, setShow] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [subStatus, setSubStatus] = useState(false);
  const [subValidTill, setSubValidTill] = useState(false);
  const [cancelSubDiv, setCancelSubDiv] = useState(false);
  const [subValid, setSubValid] = useState(false);
  const [cancelButton, setCancelButton] = useState(true);
  const [resubButton, setReSubButton] = useState(false);
  const [subDetails, setSubDetails] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleCloseCancel = () => setShowCancelModal(false);
  const handleShow = () => {
    setShow(true);
  };
  //   const logout = async () => {
  //     console.log(logout);
  //     console.log(setLoggedInId);
  //     // setLoggedInId('')
  //     if (loggedInId && loggedInId !== "") {
  //       try {
  //         const response = await axios
  //           .post(`${process.env.REACT_APP_API_ENDPOINT}/logout`, {
  //             user_id: JSON.parse(localStorage.getItem("token")),
  //           })
  //           .then((response) => {
  //             console.log("hello", response);
  //             localStorage.removeItem("token");
  //             localStorage.removeItem("customer_id");
  //             localStorage.removeItem("payment");
  //             localStorage.removeItem("number");
  //             setIsPaymentDone(false);
  //             if (response.data.status === true) {
  //               setLoggedInId("");
  //               notify();

  //               <Navigate to="/extension" replace={true} />;

  //               delete axios.defaults.headers.common["x-access-token"];
  //               setSubStatus(false);
  //             }
  //             setShowPaymentModal(false);
  //             setPaymentPopup(false);
  //           });
  //       } catch (err) {
  //         console.log(err);
  //         toast.error(err.message);
  //       }
  //     }
  //   };

  const logout = async () => {
    if (loggedInId && loggedInId !== "") {
      try {
        const response = await axios
          .post(`${process.env.REACT_APP_API_ENDPOINT}/logout`, {
            user_id: JSON.parse(localStorage.getItem("token")),
          })
          .then((response) => {
            navigate("/");
            setLoggedInId("payment");
            localStorage.setItem("token", "");
            localStorage.removeItem("customer_id");
            localStorage.removeItem("payment");
            localStorage.removeItem("number");
            localStorage.removeItem("key");
            setIsPaymentDone(false);
            if (response.data.status === true) {
              localStorage.setItem("token", "");
              setIsPaymentDone(false);
              setLoggedInId("");
              notify();
              delete axios.defaults.headers.common["x-access-token"];
              setSubStatus(false);
              window.location.reload();
            }
            setShowPaymentModal(false);

            setPaymentPopup(false);
          });
      } catch (err) {
        // console.log(err);
        toast.error(err.message);
      }
    }
  };

  const todayDate = new Date();
  const checkLoggedInStatus = async () => {
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/check-payment-status`,
          {
            customer_id: localStorage.getItem("customer_id")
              ? localStorage.getItem("customer_id").toString()
              : "",
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          var dateObj = new Date(value.data.current_period_end * 1000);
          var date =
            dateObj.getDate() +
            "/" +
            (dateObj.getMonth() + 1) +
            "/" +
            dateObj.getFullYear();
          if (value.data.subscription == "active") {
            setIsPaymentDone(true);
            setShowSub(true);
            localStorage.setItem("payment", true);
            setSubStatus(true);
            setSubValidTill(date);
            setShowInst(true);
            setPaymentPopup(false);
            setShow(false);
          } else if (value.data.subscription == "canceled") {
            if (value.data.current_period_end) {
              if (dateObj > todayDate) {
                setReSubButton(true);
                setCancelButton(false);
                setCancelSubDiv(false);
                setSubDetails(true);
                setIsPaymentDone(true);
                setShowSub(true);
                setSubValidTill(date);
                setSubStatus(true);
                setShowInst(true);
              } else {
                setSubStatus(false);
              }
            }
          } else if (value.data.status == false) {
            localStorage.setItem("payment", false);
          }
          console.log("value", value);
        });
    } catch (err) {
      console.log(err);
      if (err.request) {
        if (err.request.status == 401) {
          setLoggedInId("");
          setPaymentPopup("");
          localStorage.removeItem("token");
          localStorage.removeItem("customer_id");
          localStorage.removeItem("payment");
          localStorage.removeItem("number");
        }
      }
    }
    var token = localStorage.getItem("token");
    if (localStorage.getItem("customer_id") === null) {
      console.log("tokennnnnnnnnnn", token);
      localStorage.removeItem("token");
    }
  };
  useEffect(() => {
    checkLoggedInStatus();
  }, []);

  useEffect(() => {
    console.log(showSub);
  }, [showSub]);

  const showPaymentModalHandler = () => {
    handleShow();
    setShowPaymentModal(true);
  };
  const cancelSubFuncSubmit = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/cancel-subscription`,
        {
          customer_id: localStorage.getItem("customer_id").toString(),
        },
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((value) => {
        console.log(value);
        if (value.data.status) {
          toast.success(value.data.msg);
          // setShowCancelModal(false)
          setShowSub(true);
          checkLoggedInStatus();
          setSubDetails(true);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const cancelSubFunc = () => {};
  return (
    <>
      <Toaster
        toastOptions={{
          className: "",
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#713200",
          },
        }}
      />
      <div key={"lg"} bg="" className="mb-3 topNavbar">
        <div className="navbar">
          <span className="logo" id="AnswersAi">
            <span className="d-flex AnswerAilogo">
              <span className="AnsAilogo">
                {/* <img src={logo} alt={logo} /> */}
                AutoSwiper.Ai
              </span>
            </span>
          </span>
          <span
            className="login-btn"
            id={`offcanvasNavbar-expand-${"lg"}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${"lg"}`}
            placement="end"
          >
            <Example />
            {loggedInId === "" || !loggedInId ? (
              <Button variant="" onClick={() => handleShow()}>
                Get Started
              </Button>
            ) : (
              <div className="d-flex">
                {isPaymentDone ? (
                  ""
                ) : (
                  <Button
                    className="me-3"
                    onClick={() => showPaymentModalHandler()}
                  >
                    Subscribe
                  </Button>
                )}
                {showSub ? (
                  <Button
                    className="me-3 cancel-btn"
                    onClick={() => setShowCancelModal(true)}
                  >
                    Subscription
                  </Button>
                ) : (
                  ""
                )}
                <Button variant="" onClick={() => logout()}>
                  Logout
                </Button>
              </div>
            )}
          </span>
        </div>
      </div>
      <LoginModal
        show={show}
        // setShow={setShow}
        handleClose={handleClose}
        setLoggedInId={setLoggedInId}
        loggedInId={loggedInId}
        showPaymentModal={showPaymentModal}
        load={load}
        setIsPaymentDone={setIsPaymentDone}
        setPaymentPopup={setPaymentPopup}
        paymentPopup={paymentPopup}
        checkLoggedInStatus={checkLoggedInStatus}
      />
      <Modal
        show={showCancelModal}
        onHide={handleCloseCancel}
        centered
        className="cancel-modal text-light"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-light">Subscription Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-light">
            Subscription Status: {subStatus ? "True" : "False"}
          </div>
          <div className="text-light">
            Subscription Valid Till: {subValidTill}
          </div>
          {cancelButton ? (
            <Button
              variant="danger"
              className="mt-3 Cancel_Subscription"
              onClick={() => setCancelSubDiv(!cancelSubDiv)}
            >
              Cancel Subscription
            </Button>
          ) : (
            ""
          )}
          {cancelSubDiv ? (
            <div className="mt-2 card p-3 text-light">
              <div className="mb-2 text-light">
                Are you sure you want to cancel your subscription?
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  variant="secondary"
                  className="me-3"
                  onClick={() => setCancelSubDiv(!cancelSubDiv)}
                >
                  No
                </Button>
                <Button variant="danger" onClick={() => cancelSubFuncSubmit()}>
                  Yes
                </Button>
              </div>
            </div>
          ) : (
            ""
          )}
          {subDetails ? (
            <div className="pt-2 text-light">
              Your subscription plan has been cancelled
              <div>
                You can keep using your account until your next payment was due
              </div>
            </div>
          ) : (
            ""
          )}
          {resubButton ? (
            <div className="text-center">
              <Button
                variant=""
                className="resub-button mt-3"
                onClick={() => showPaymentModalHandler()}
              >
                Resubscribe
              </Button>
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TopBar;
