import React, { useEffect } from "react";
import checkmark from "./images/checkmark.png";
const AlreadyLoggedIn = () => {
  useEffect(() => {
    window.rdt("track", "PageVisit", {
      products: [
        {
          name: "Alredylogin",
        },
      ],
    });
  }, []);

  return (
    <div className="extension-done-modal d-flex flex-column justify-content-center align-items-center vh-100">
      <div className="d-flex align-items-center">
        You are successfully logged in.
        <img
          style={{ width: "20px", marginLeft: "10px" }}
          src={checkmark}
          alt=""
        />
      </div>
      You can close this popup now!
    </div>
  );
};

export default AlreadyLoggedIn;
