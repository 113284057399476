import React, {useEffect, useState} from 'react';
import {PaymentRequestButtonElement, useStripe, useElements} from '@stripe/react-stripe-js';

const GoogleApplePay = ({completePaymentProcess}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'AutoSwiper.Ai Monthly Subscription',
        amount: 99,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then(result => {
      if (result) {
				console.log(result);
				if (result.applePay === true || result.googlePay === true) {
					setPaymentRequest(pr);
				}
      }
    });
    pr.on('token', function() {
      console.log("token");
    })
    pr.on('paymentmethod', function() {
      console.log("paymentmethod");
    })
    pr.on('paymentmethod', async (e) => { 
      console.log('paymentmethod');
      // const {error: backendError, client_secret} = await fetch(
      //   `${process.env.REACT_APP_API_ENDPOINT}/create-payment-intent`,
      //   {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'x-access-token': JSON.parse(localStorage.getItem('token'))
      //     },
      //     body: JSON.stringify({
      //       paymentMethodType: 'card',
      //       currency: 'usd',
      //     }),
      //   }
      // ).then((r) => r.json());

      // if (backendError) {
      //   console.log(backendError.message);
      //   return;
      // }

			// console.log("clientSecret", client_secret);
      // console.log('Client secret returned');

      // const {
      //   error: stripeError,
      //   paymentIntent,
      // } = await stripe.confirmCardPayment(client_secret, {
      //   payment_method: e.paymentMethod.id,
      // }, { handleActions: false });

      // if (stripeError) {
      //   // Show error to your customer (e.g., insufficient funds)
      //   console.log(stripeError.message);
      //   return;
      // }

      // // Show a success message to your customer
      // // There's a risk of the customer closing the window before callback
      // // execution. Set up a webhook or plugin to listen for the
      // // payment_intent.succeeded event that handles any business critical
      // // post-payment actions.
			// console.log("paymentIntent", paymentIntent);
      // console.log(`Payment ${paymentIntent.status}: ${paymentIntent}`);
      // if(paymentIntent.id && paymentIntent.id !== '') {
      //   localStorage.setItem('paymentIntentId', paymentIntent.id)
      // }
      completePaymentProcess(e.paymentMethod.id)
    });
  }, [stripe, elements, completePaymentProcess]);

  return (
    <>

      {paymentRequest && <PaymentRequestButtonElement options={{paymentRequest}} />}

    </>
  );
};

export default GoogleApplePay;
