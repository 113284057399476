import React, { useState, useEffect, useRef } from "react";
// import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Form, Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-phone-number-input/style.css";
import picture from "../components/images/home-t.png";
import OTPInput from "otp-input-react";
import logo from "../components/images/logo-white.svg";
import social from "../components/images/social.png";
import mylogo from "../components/images/mylogo.png";
import axios from "axios";
import pencil from "./images/pencil-icon.png";
import toast, { Toaster } from "react-hot-toast";
import OtpVerifyOtp from "./OtpVerifyOtp";

// import { useTimer } from 'use-timer';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../firebase";
import StripeContainer from "./StripeContainer";
import ClipLoader from "react-spinners/ClipLoader";
import { hostname } from "./hostname";

function LoginModal({
  show,
  handleClose,
  setLoggedInId,
  paymentPopupProp,
  load,
  showPaymentModal,
  setIsPaymentDone,
  paymentPopup,
  setPaymentPopup,
  checkLoggedInStatus,
}) {
  const [error, setError] = useState("");
  // const {setUpRecaptcha} = useUserAuth();
  const [number, setNumber] = useState("");
  const [result, setResult] = useState("");
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  // const navigate = useNavigate();
  const [hideResend, setHideResend] = useState(true);

  const [counter, setCounter] = useState(1);

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("token") !== "") {
      setPaymentPopup(true);
    } else {
      setPaymentPopup(false);
    }
  }, []);

  useEffect(() => {
    // if(flag === true) {
    if (counter === 0) {
      setHideResend(false);
    }
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    //console.log(timer);
    return () => clearInterval(timer);
    // }
  }, [counter]);

  const resendCode = (e) => {
    setHideResend(true);
    if (hideResend === false) {
      setCounter(59);

      resendOtp(e);
    }
  };

  function setUpRecaptcha(number) {
    if (Window.recaptchaVerifier) {
      return signInWithPhoneNumber(auth, number, Window.recaptchaVerifier);
    }
    Window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      { size: "invisible" },
      auth
    );
    // recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, Window.recaptchaVerifier);
  }
  function setUpRecaptcha2(number) {
    if (Window.recaptchaVerifier) {
      return signInWithPhoneNumber(auth, number, Window.recaptchaVerifier);
    }
    // Window.recaptchaVerifier.clear()

    Window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container2",
      { size: "invisible" },
      auth
    );
    // recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, Window.recaptchaVerifier);
  }

  const editFunction = (e) => {
    e.preventDefault();
    setFlag(false);
  };

  const getOtp = async (e) => {
    e.preventDefault();
    console.log(number);
    setError("");
    if (number === "" || number === undefined)
      return setError("Please enter a valid phone number!");

    load.current.style.display = "flex";
    try {
      const response = await setUpRecaptcha(number);
      console.log(response);
      setResult(response);
      setFlag(true);
      setCounter(59);
      setHideResend(true);
      load.current.style.display = "none";
    } catch (err) {
      setError(err.message);
      load.current.style.display = "none";
    }
  };

  const resendOtp = async (e) => {
    console.log(number);
    setError("");

    if (number === "" || number === undefined)
      return setError("Please enter a valid phone number!");
    try {
      const response = await setUpRecaptcha2(number);
      console.log(response);
      setResult(response);
      setFlag(true);
      setCounter(59);
    } catch (err) {
      setError(err.message);
    }
  };
  const verifyOtp = async (e) => {
    //setPaymentPopup(true)
    e.preventDefault();
    setError("");

    if (otp === "" || otp === null) return setError("Please Enter a OTP");
    load.current.style.display = "flex";

    try {
      const res = await result.confirm(otp);

      console.log("res___", res);

      console.log("confirm", res._tokenResponse.idToken);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/login-user`,
          {
            mobile_number: number,
            tokenid: res._tokenResponse.idToken,
          }
        );
        if (response.data.status == true) {
          console.log(response.data.msg);
          setLoggedInId(response.data.token);
          console.log("error_-__00");
          if (response.data.token && response.data.token !== "") {
            localStorage.setItem("token", JSON.stringify(response.data.token));
          } else {
            localStorage.removeItem("token");
          }
          localStorage.setItem("customer_id", response.data.user_id);
          localStorage.setItem("number", JSON.stringify(number));
          console.log("error_-__0");
          setPaymentPopup(true);
          console.log("error_-__1");
          checkLoggedInStatus();

          console.log("error_-__2");
        } else {
          console.log(
            `<span className="text-dark">${response.data.msg}</span>`
          );
          toast.error(response.data.msg);
        }
        load.current.style.display = "none";
      } catch (error) {
        console.log("Error", error);
        toast.error(error.message);
        load.current.style.display = "none";
      }
    } catch (err) {
      setError(err.message);
      load.current.style.display = "none";
    }
  };

  return (
    <>
      <Modal
        className="swiper-modal"
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {paymentPopup === true || paymentPopupProp === true ? (
          <StripeContainer
            mobile_number={number}
            load={load}
            showPaymentModal={showPaymentModal}
            setIsPaymentDone={setIsPaymentDone}
            setPaymentPopup={setPaymentPopup}
          />
        ) : (
          <div>
            <div
              className="ring-loader"
              ref={load}
              style={{ display: "none", placeContent: "center" }}
            >
              <ClipLoader size={250} color={"#fff"} />
            </div>
            <Form
              onSubmit={getOtp}
              style={{ display: !flag ? "block" : "none" }}
            >
              <Form.Group>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="show-grid">
                  <Container>
                    <div className="sign-form">
                      <Col lg={12} md={12} className="for-app">
                        <div className="swiper-heading"></div>
                        <div className="user-box">
                          <div className="tinder-baseline">
                            <h3>Are you ready to start getting matches?</h3>
                            <p>
                              One million swipes and counting! Let’s get you set
                              up.
                            </p>
                          </div>
                          <img
                            src={social}
                            alt=""
                            className="login-popup-img"
                          />
                          <p className="loginModel-p">
                            Please enter your phone number below to signup and
                            get started
                          </p>
                          <PhoneInput
                            international
                            defaultCountry="US"
                            value={number}
                            onChange={setNumber}
                            placeholder="Enter Your Phone Number"
                          />
                        </div>
                        <div
                          id="recaptcha-container"
                          className="ml-2  mb-5 recaptcha-container"
                        ></div>
                        <Button
                          id="login-modal-btn"
                          type="submit"
                          className="payment-button"
                        >
                          Sign me up
                        </Button>
                        <div className="extenstion-information">
                          <p className="text-white">
                            Click on "Continue" to receive a verification code.
                            Message and data rates may apply. By clicking
                            continue, you agree to the <br />{" "}
                            <a
                              href={`${process.env.PUBLIC_URL}/terms-of-use`}
                              target="_blank"
                            >
                              Terms of Service
                            </a>{" "}
                            and{" "}
                            <a
                              href={`${process.env.PUBLIC_URL}/privacy-policy`}
                              target="_blank"
                            >
                              Privacy Policy
                            </a>
                            .
                          </p>
                        </div>
                      </Col>
                    </div>
                  </Container>
                  <Col md={12} className="error">
                    {error && <Alert variant="danger">{error}</Alert>}
                  </Col>
                </Modal.Body>
              </Form.Group>
            </Form>
            <Form
              className="hidden-form"
              onSubmit={verifyOtp}
              style={{ display: flag ? "block" : "none" }}
            >
              <Modal.Header closeButton></Modal.Header>
              <OtpVerifyOtp
                editFunction={editFunction}
                otp={otp}
                setOtp={setOtp}
                counter={counter}
                hideResend={hideResend}
                resendCode={resendCode}
                error={error}
                number={number}
              />
            </Form>
            <Form></Form>
          </div>
        )}
      </Modal>
    </>
  );
}

export default LoginModal;
