import React, { useState, useRef, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import LikePic from '../components/images/like.png'
import Card from "react-bootstrap/Card";
// import CardImg1 from '../components/images/mylogo.png';
import extension from "../components/images/tips.png";
import tips2 from "../components/images/tips2.png";
import rizz_mode from "../components/images/rizz_mode.png";
import setlogo from "../components/images/set-icon.svg";
import signupLogo from "../components/images/sign-up-icon.svg";
import LoginModal from "./LoginModal";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";

export default function SectionSecond({
  loggedInId,
  setLoggedInId,
  load,
  isPaymentDone,
  setIsPaymentDone,
  paymentPopup,
  setPaymentPopup,
  showInst,
  setShowInst,
  showSub,
  setShowSub,
  showPaymentModal,
  setShowPaymentModal,
}) {
  const todayDate = new Date();
  const [showCancelModal, setShowCancelModal] = useState(false);

  const [subStatus, setSubStatus] = useState(false);
  const [subValidTill, setSubValidTill] = useState(false);
  const [cancelSubDiv, setCancelSubDiv] = useState(false);
  const [cancelButton, setCancelButton] = useState(true);
  const [resubButton, setReSubButton] = useState(false);
  const [subDetails, setSubDetails] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseCancel = () => setShowCancelModal(false);

  const HandleModel = () => {
    if (!loggedInId) {
      handleShow();
    } else {
      if (!isPaymentDone) {
        showPaymentModalHandler();
      } else if (showSub) {
        setShowCancelModal(true);
      }
    }
  };
  const showPaymentModalHandler = () => {
    handleShow();
    setShowPaymentModal(true);
  };

  const cancelSubFuncSubmit = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/cancel-subscription`,
        {
          customer_id: localStorage.getItem("customer_id").toString(),
        },
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((value) => {
        // console.log(value);
        if (value.data.status) {
          toast.success(value.data.msg);
          // setShowCancelModal(false)
          setShowSub(true);
          checkLoggedInStatus();
          setSubDetails(true);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    checkLoggedInStatus();
  }, []);

  const checkLoggedInStatus = async () => {
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/check-payment-status`,
          {
            customer_id: localStorage.getItem("customer_id")
              ? localStorage.getItem("customer_id").toString()
              : "",
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          var dateObj = new Date(value.data.current_period_end * 1000);
          var date =
            dateObj.getDate() +
            "/" +
            (dateObj.getMonth() + 1) +
            "/" +
            dateObj.getFullYear();
          if (value.data.subscription == "active") {
            setIsPaymentDone(true);
            setShowSub(true);
            localStorage.setItem("payment", true);
            setSubStatus(true);
            setSubValidTill(date);
            setShowInst(true);
            setPaymentPopup(false);
            setShow(false);
          } else if (value.data.subscription == "canceled") {
            if (value.data.current_period_end) {
              if (dateObj > todayDate) {
                setReSubButton(true);
                setCancelButton(false);
                setCancelSubDiv(false);
                setSubDetails(true);
                setIsPaymentDone(true);
                setShowSub(true);
                setSubValidTill(date);
                setSubStatus(true);
                setShowInst(true);
              } else {
                setSubStatus(false);
              }
            }
          }
          console.log("value", value);
        });
    } catch (err) {
      console.log(err);
      if (err.request) {
        if (err.request.status == 401) {
          setLoggedInId("");
          setPaymentPopup("");
          localStorage.removeItem("token");
          localStorage.removeItem("customer_id");
          localStorage.removeItem("payment");
          localStorage.removeItem("number");
        }
      }
    }
    var token = localStorage.getItem("token");
    if (localStorage.getItem("customer_id") === null) {
      // console.log("tokennnnnnnnnnn", token);
      localStorage.removeItem("token");
    }
  };

  return (
    <>
      <section className="Second-Section">
        <div className="Second container-fluid">
          <Row>
            <Col lg={1} md={12} sm={12}></Col>
            <Col lg={10} md={12} sm={12} className="it-work-heading">
              <h2 className="">It’s okay to have a preference!</h2>
            </Col>
            <Col lg={1} md={12} sm={12}></Col>
          </Row>
          <Row className="reverse-div pt-5 mt-4">
            <Col
              xl={5}
              lg={6}
              md={12}
              sm={12}
              className="d-lg-flex align-items-center"
            >
              <div className="px-3 w-100">
                <div id="SecondsTags">Our Filters</div>
                <p className="peragraph mt-4">
                  <div className="text-dark">
                    Not into big girls? That’s okay! <br />
                    Not into gay people? That’s okay! <br />
                    Only looking for dimes? THAT’S OKAY! <br />
                    <br />
                    Select you filter’s and let our Ai do the rest.
                  </div>
                </p>
                {!loggedInId ? (
                  <div className="LetsDoItDiv">
                    <button className="LetsDoIt" onClick={() => HandleModel()}>
                      Get Started
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col xl={7} lg={6} md={12} sm={12} className="mt-5 mb-4 ">
              <img
                src={extension}
                className="Our_Filters_pic pe-xl-5"
                style={{ width: "100%", height: "100%" }}
                alt=""
              />
            </Col>
          </Row>
          <Row className="reverse2-div">
            <Col xl={7} lg={6} md={12} sm={12} className="mt-5 mb-4 ">
              <img
                className="Our_Filters_pic"
                src={tips2}
                style={{ width: "100%", height: "100%" }}
                alt=""
              />
            </Col>
            <Col
              xl={5}
              lg={6}
              md={12}
              sm={12}
              className=" d-flex align-items-center"
            >
              <div className="px-3 w-100">
                <div id="SecondsTags">Set Controls</div>
                <p className="peragraph mt-4">
                  <div className="text-dark">
                    Set your preferences to make sure you <br />
                    get exactly what your looking for. <br /> From age,
                    distance, to swipe time. Set <br />
                    and turn on for the ultimate autoswipe <br /> experience.
                  </div>
                </p>
                {!loggedInId ? (
                  <div className="LetsDoItDiv">
                    <button className="LetsDoIt" onClick={() => HandleModel()}>
                      Get Started
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
          <Row className="reverse3-div">
            <Col
              xl={5}
              lg={6}
              md={12}
              sm={12}
              className=" d-flex align-items-center"
            >
              <div className="px-3 w-100">
                <div id="SecondsTags">Auto Pickup Lines</div>
                <p className="peragraph mt-4">
                  <div className="text-dark">
                    Got a match but not sure what to say? <br />
                    Simply press RIZZ MODE and let our Ai <br />
                    make a pickup line for you based no <br /> there bio.
                  </div>
                </p>
                {!loggedInId ? (
                  <div className="LetsDoItDiv">
                    <button className="LetsDoIt" onClick={() => HandleModel()}>
                      Get Started
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col xl={7} lg={6} md={12} sm={12} className="mt-5 mb-4 px-4">
              <img
                className="Our_Filters_pic pe-xl-5"
                src={rizz_mode}
                style={{ width: "100%", height: "100%" }}
                alt=""
              />
            </Col>
          </Row>
        </div>
      </section>

      <LoginModal
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        setLoggedInId={setLoggedInId}
        loggedInId={loggedInId}
        showPaymentModal={showPaymentModal}
        load={load}
        setIsPaymentDone={setIsPaymentDone}
        setPaymentPopup={setPaymentPopup}
        paymentPopup={paymentPopup}
        checkLoggedInStatus={checkLoggedInStatus}
      />
      <Modal
        show={showCancelModal}
        onHide={handleCloseCancel}
        centered
        className="cancel-modal text-light"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-light">Subscription Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-light">
            Subscription Status: {subStatus ? "True" : "False"}
          </div>
          <div className="text-light">
            Subscription Valid Till: {subValidTill}
          </div>
          {cancelButton ? (
            <Button
              variant="danger"
              className="mt-3 Cancel_Subscription"
              onClick={() => setCancelSubDiv(!cancelSubDiv)}
            >
              Cancel Subscription
            </Button>
          ) : (
            ""
          )}
          {cancelSubDiv ? (
            <div className="mt-2 card p-3 text-light">
              <div className="mb-2 text-light">
                Are you sure you want to cancel your subscription?
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  variant="secondary"
                  className="me-3"
                  onClick={() => setCancelSubDiv(!cancelSubDiv)}
                >
                  No
                </Button>
                <Button variant="danger" onClick={() => cancelSubFuncSubmit()}>
                  Yes
                </Button>
              </div>
            </div>
          ) : (
            ""
          )}
          {subDetails ? (
            <div className="pt-2 text-light">
              Your subscription plan has been cancelled
              <div>
                You can keep using your account until your next payment was due
              </div>
            </div>
          ) : (
            ""
          )}
          {resubButton ? (
            <div className="text-center">
              <Button
                variant=""
                className="resub-button mt-3"
                onClick={() => showPaymentModalHandler()}
              >
                Resubscribe
              </Button>
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
