import "./App.css";
// import Footer from './components/Footer';
// import SectionOne from './components/SectionOne';
// import SectionSecond from './components/SectionSecond';
// import SectionSix from './components/SectionSix';
// import TopBar from './components/TopBar';
import { useState, useEffect, useRef } from "react";
import Extension from "./components/Extension";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import AlreadyLoggedIn from "./components/AlreadyLoggedIn";
import InstructionPopup from "./components/InstructionPopup";
import TermsOfUse from "./components/TermsForUse";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {
  const [loggedInId, setLoggedInId] = useState("");
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [showInst, setShowInst] = useState(false);
  const [showSub, setShowSub] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const load = useRef();

  useEffect(() => {
    setLoggedInId(JSON.parse(localStorage.getItem("token")));
    setIsPaymentDone(JSON.parse(localStorage.getItem("payment")));
  }, []);
  return (
    <>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route
              index
              element={
                <Home
                  showPaymentModal={showPaymentModal}
                  setShowPaymentModal={setShowPaymentModal}
                  loggedInId={loggedInId}
                  setLoggedInId={setLoggedInId}
                  load={load}
                  isPaymentDone={isPaymentDone}
                  setIsPaymentDone={setIsPaymentDone}
                  setPaymentPopup={setPaymentPopup}
                  paymentPopup={paymentPopup}
                  showInst={showInst}
                  setShowInst={setShowInst}
                  showSub={showSub}
                  setShowSub={setShowSub}
                />
              }
            />
            {isPaymentDone ? (
              <Route
                path="extension"
                element={
                  <AlreadyLoggedIn
                    load={load}
                    loggedInId={loggedInId}
                    setLoggedInId={setLoggedInId}
                  />
                }
              />
            ) : (
              <Route
                path="extension"
                element={
                  <Extension
                    load={load}
                    loggedInId={loggedInId}
                    setLoggedInId={setLoggedInId}
                    isPaymentDone={isPaymentDone}
                    setIsPaymentDone={setIsPaymentDone}
                  />
                }
              />
            )}
            <Route path="terms-of-use" element={<TermsOfUse />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </BrowserRouter>
        <InstructionPopup showInst={showInst} setShowInst={setShowInst} />
      </div>
    </>
  );
}

export default App;
