import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  const redirectOnHomepage = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    window.rdt("track", "PageVisit", {
      products: [
        {
          name: "privacy-policy",
        },
      ],
    });
  }, []);

  return (
    <div>
      <div className="container" style={{ padding: "2em" }}>
        <div className="text-center row">
          <div
            className="col-12 privacy_policy_heading fs-3 fw-bold logo-color"
            onClick={() => redirectOnHomepage()}
          >
            AutoSwiper.Ai
          </div>
        </div>
        <div className="row container-color">
          <div className="col-12 mt-3">
            <h4 style={{ color: "black" }}>Privacy Policy</h4>
          </div>
          <p className="mt-4">
            This privacy notice for AutoSwiper.Ai (&quot;Company,&quot;
            &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;), describes how
            and why we might collect, store, use, and/or share
            (&quot;process&quot;) your information when you use our services
            (&quot;Services&quot;), such as when you:
          </p>

          <p>
            Visit our website at{" "}
            <a
              className="text-dark"
              target="_blank"
              href="https://autoswiper.ai"
            >
              https://autoswiper.ai
            </a>
            , or any website of ours that links to this privacy notice Engage
            with us in other related ways, including any sales, marketing, or
            events Questions or concerns? Reading this privacy notice will help
            you understand your privacy rights and choices. If you do not agree
            with our policies and practices, please do not use our Services. If
            you still have any questions or concerns, please contact us at{" "}
            <a
              className="text-dark"
              target="_blank"
              href="mailto:autoswiperai@gmail.com"
            >
              autoswiperai@gmail.com
            </a>
          </p>

          <p>SUMMARY OF KEY POINTS</p>

          <p>
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our table of contents
            below to find the section you are looking for.
          </p>

          <p>
            What personal information do we process? When you visit, use, or
            navigate our Services, we may process personal information depending
            on how you interact with AutoSwiper.Ai and the Services, the choices
            you make, and the products and features you use.
          </p>

          <p>
            Do we process any sensitive personal information? We do not process
            sensitive personal information.
          </p>

          <p>
            Do we receive any information from third parties? We do not receive
            any information from third parties.
          </p>

          <p>
            How do we process your information? We process your information to
            provide, improve, and administer our Services, communicate with you,
            for security and fraud prevention, and to comply with law. We may
            also process your information for other purposes with your consent.
            We process your information only when we have a valid legal reason
            to do so.
          </p>

          <p>
            In what situations and with which types of parties do we share
            personal information? We may share information in specific
            situations and with specific categories of third parties.
          </p>

          <p>
            How do we keep your information safe? We have organizational and
            technical processes and procedures in place to protect your personal
            information. However, no electronic transmission over the internet
            or information storage technology can be guaranteed to be 100%
            secure, so we cannot promise or guarantee that hackers,
            cybercriminals, or other unauthorized third parties will not be able
            to defeat our security and improperly collect, access, steal, or
            modify your information.
          </p>

          <p>
            What are your rights? Depending on where you are located
            geographically, the applicable privacy law may mean you have certain
            rights regarding your personal information.
          </p>

          <p>
            How do you exercise your rights? The easiest way to exercise your
            rights is by filling out our data subject request form available
            here
            (https://app.termly.io/notify/7ee190ca-6355-4113-9685-0bdf39d05164)
            , or by contacting us. We will consider and act upon any request in
            accordance with applicable data protection laws.
          </p>

          <p>
            Want to learn more about what AutoSwiper.Ai does with any
            information we collect? Click here to review the notice in full.
          </p>

          <p>TABLE OF CONTENTS</p>

          <p>
            WHAT INFORMATION DO WE COLLECT? HOW DO WE PROCESS YOUR INFORMATION?
            WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
            WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? DO WE USE
            COOKIES AND OTHER TRACKING TECHNOLOGIES? GOOGLE ADSENSE AND THE
            DOUBLECLICK DART COOKIE THIRD-PARTY ADVERTISERS HOW DO WE HANDLE
            YOUR SOCIAL LOGINS? HOW LONG DO WE KEEP YOUR INFORMATION? HOW DO WE
            KEEP YOUR INFORMATION SAFE? WHAT ARE YOUR PRIVACY RIGHTS? CONTROLS
            FOR DO-NOT-TRACK FEATURES DO CALIFORNIA RESIDENTS HAVE SPECIFIC
            PRIVACY RIGHTS? DO WE MAKE UPDATES TO THIS NOTICE? HOW CAN YOU
            CONTACT US ABOUT THIS NOTICE? HOW CAN YOU REVIEW, UPDATE, OR DELETE
            THE DATA WE COLLECT FROM YOU? 1. WHAT INFORMATION DO WE COLLECT?
            Personal information you disclose to us In Short: We collect
            personal information that you provide to us.
          </p>

          <p>
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.
          </p>

          <p>
            Personal Information Provided by You. The personal information that
            we collect depends on the context of your interactions with us and
            the Services, the choices you make, and the products and features
            you use. The personal information we collect may include the
            following:
          </p>

          <p>
            names email addresses passwords usernames billing addresses
            debit/credit card numbers Sensitive Information. We do not process
            sensitive information.
          </p>

          <p>
            Payment Data. We may collect data necessary to process your payment
            if you make purchases, such as your payment instrument number, and
            the security code associated with your payment instrument. All
            payment data is stored by Stripe. You may find their privacy notice
            link(s) here: https://stripe.com/privacy.
          </p>

          <p>
            Social Media Login Data. We may provide you with the option to
            register with us using your existing social media account details,
            like your Facebook, Twitter, or other social media account. If you
            choose to register in this way, we will collect the information
            described in the section called &quot;HOW DO WE HANDLE YOUR SOCIAL
            LOGINS? (#sociallogins) &quot; below.
          </p>

          <p>
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>

          <p>
            Information automatically collected In Short: Some information
            &mdash; such as your Internet Protocol (IP) address and/or browser
            and device characteristics &mdash; is collected automatically when
            you visit our Services.
          </p>

          <p>
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
          </p>

          <p>
            Like many businesses, we also collect information through cookies
            and similar technologies.
          </p>

          <p>The information we collect includes:</p>

          <p>
            Log and Usage Data. Log and usage data is service-related,
            diagnostic, usage, and performance information our servers
            automatically collect when you access or use our Services and which
            we record in log files. Depending on how you interact with us, this
            log data may include your IP address, device information, browser
            type, and settings and information about your activity in the
            Services (such as the date/time stamps associated with your usage,
            pages and files viewed, searches, and other actions you take such as
            which features you use), device event information (such as system
            activity, error reports (sometimes called &quot;crash dumps&quot;),
            and hardware settings).
          </p>

          <p>
            Device Data. We collect device data such as information about your
            computer, phone, tablet, or other device you use to access the
            Services. Depending on the device used, this device data may include
            information such as your IP address (or proxy server), device and
            application identification numbers, location, browser type, hardware
            model, Internet service provider and/or mobile carrier, operating
            system, and system configuration information.
          </p>

          <p>
            Location Data. We collect location data such as information about
            your device's location, which can be either precise or imprecise.
            How much information we collect depends on the type and settings of
            the device you use to access the Services. For example, we may use
            GPS and other technologies to collect geolocation data that tells us
            your current location (based on your IP address). You can opt out of
            allowing us to collect this information either by refusing access to
            the information or by disabling your Location setting on your
            device. However, if you choose to opt out, you may not be able to
            use certain aspects of the Services.
          </p>

          <p>
            Collection of Assignment Data. When you use our Chrome extension on
            Learning Management Systems (LMS) platforms, such as but not limited
            to, McGraw Hill Connect and Canvas, we collect information related
            to the assignment questions and user-generated answers. This
            information may include, but is not limited to, question text,
            answer choices, and your inputted answers.
          </p>

          <p>
            Purpose of Data Collection. We collect this information for the
            purpose of improving the accuracy and effectiveness of our Chrome
            extension. By gathering data on assignment questions and
            user-generated answers, we can train and enhance our artificial
            intelligence algorithms to provide better assistance to users in the
            future.
          </p>

          <p>
            Data Sharing and Disclosure. We may share or disclose the collected
            data with third parties for the purpose of data analysis, machine
            learning, or other similar purposes, provided that the shared data
            is anonymized and does not contain any personally identifiable
            information. We will not sell or rent your assignment data to third
            parties for marketing purposes or any other purpose unrelated to the
            operation and improvement of our Chrome extension.
          </p>

          <p>
            Data Retention. We will retain the collected data for as long as
            necessary to achieve our stated purposes or as required by
            applicable laws and regulations. We take reasonable measures to
            ensure the security and integrity of the collected data during
            storage and processing.
          </p>

          <p>
            User Consent. By using our Chrome extension, you acknowledge and
            consent to the collection, use, and disclosure of your assignment
            data as described in this privacy policy. If you do not agree with
            our data collection and usage practices, you should not use our
            Chrome extension.
          </p>

          <p>
            HOW DO WE PROCESS YOUR INFORMATION? In Short: We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other
            purposes with your consent.
          </p>

          <p>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </p>

          <p>
            To facilitate account creation and authentication and otherwise
            manage user accounts. To request feedback. To send you marketing and
            promotional communications. To deliver targeted advertising to you.
            To protect our Services. To identify usage trends. To determine the
            effectiveness of our marketing and promotional campaigns. To save or
            protect an individual's vital interest. To provide the search
            feature on our website. To comply with legal obligations. WHAT LEGAL
            BASES DO WE RELY ON TO PROCESS YOUR INFORMATION? In Short: We only
            process your personal information when we believe it is necessary
            and we have a valid legal reason (i.e., legal basis) to do so under
            applicable law, like with your consent, to comply with laws, to
            provide you with services to enter into or fulfill our contractual
            obligations, to protect your rights, or to fulfill our legitimate
            business interests.
          </p>

          <p>
            If you are located in the EU or UK, this section applies to you.
          </p>

          <p>
            We may rely on the following legal bases to process your personal
            information:
          </p>

          <p>
            Consent. Legitimate Interests. Legal Obligations. Vital Interests.
            Contractual Obligations. In some exceptional cases, we may be
            legally permitted under applicable law to process your information
            without your consent.
          </p>

          <p>
            If collection is clearly in the interests of an individual and
            consent cannot be obtained in a timely way For investigations and
            fraud detection and prevention For business transactions provided
            certain conditions are met If it is contained in a witness statement
            and the collection is necessary to assess, process, or settle an
            insurance claim For identifying injured, ill, or deceased persons
            and communicating with next of kin If we have reasonable grounds to
            believe an individual has been, is, or may be victim of financial
            abuse If it is reasonable to expect collection and use with consent
            would compromise the availability or the accuracy of the information
            and the collection is reasonable for purposes related to
            investigating a breach of an agreement or a contravention of the
            laws of Canada or a province If disclosure is required to comply
            with a subpoena, warrant, court order, or rules of the court
            relating to the production of records If it was produced by an
            individual in the course of their employment, business, or
            profession and the collection is consistent with the purposes for
            which the information was produced If the collection is solely for
            journalistic, artistic, or literary purposes If the information is
            publicly available and is specified by the regulations WHEN AND WITH
            WHOM DO WE SHARE YOUR PERSONAL INFORMATION? In Short: We may share
            information in specific situations described in this section and/or
            with the following categories of third parties.
          </p>

          <p>
            Vendors, Consultants, and Other Third-Party Service Providers. We
            may share your data with third-party vendors, service providers,
            contractors, or agents (&quot;third parties&quot;) who perform
            services for us or on our behalf and require access to such
            information to do that work. We have contracts in place with our
            third parties, which are designed to help safeguard your personal
            information. This means that they cannot do anything with your
            personal information unless we have instructed them to do it. They
            will also not share your personal information with any organization
            apart from us. They also commit to protect the data they hold on our
            behalf and to retain it for the period we instruct. The categories
            of third parties we may share personal information with are as
            follows:
          </p>

          <p>
            Cloud Storage Providers Data Analytics Services Payment Processors
            Sales &amp; Marketing Tools Artificial Intelligence Service
            Providers We have contracts in place with our third-party service
            providers that require them to protect the data they hold on our
            behalf and to retain it only for the period we instruct. These
            contracts also prohibit third-party service providers from using
            your personal information for any purpose other than to provide
            services to us.
          </p>

          <p>
            DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? In Short: We may
            use cookies and other tracking technologies to collect and store
            your information.
          </p>

          <p>
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice.
          </p>

          <p>
            Our website uses cookies to enhance your experience while using our
            site. Cookies are small text files that are stored on your computer
            or mobile device when you visit a website. They help us remember
            your preferences and understand how you interact with our website.
          </p>

          <p>
            You can set your browser to refuse all or some cookies or to alert
            you when websites set or access cookies. However, if you disable or
            refuse cookies, some parts of our website may become inaccessible or
            not function properly.
          </p>

          <p>
            GOOGLE ADSENSE AND THE DOUBLECLICK DART COOKIE We use Google AdSense
            to display ads on our website. Google AdSense uses cookies,
            including the DoubleClick DART cookie, to serve ads based on your
            visit to our website and/or other sites on the Internet. You may opt
            out of the use of the DART cookie by visiting the Google ad and
            content network privacy policy at
            https://policies.google.com/technologies/ads.
          </p>

          <p>
            Google's use of advertising cookies enables it and its partners to
            serve ads to you based on your visit to our site and/or other sites
            on the Internet. You may opt out of personalized advertising by
            visiting Ads Settings (https://www.google.com/settings/ads) or by
            using the Google Analytics Opt-Out Browser Add-on
            (https://tools.google.com/dlpage/gaoptout/).
          </p>

          <p>
            THIRD-PARTY ADVERTISERS We may use third-party advertising companies
            to serve ads when you visit our website. These companies may use
            non-PII about your visits to this and other websites to provide
            advertisements about goods and services that may be of interest to
            you.
          </p>

          <p>
            HOW DO WE HANDLE YOUR SOCIAL LOGINS? In Short: If you choose to
            register or log in to our Services using a social media account, we
            may have access to certain information about you.
          </p>

          <p>
            Our Services offer you the ability to register and log in using your
            third-party social media account details (like your Facebook or
            Twitter logins). Where you choose to do this, we will receive
            certain profile information about you from your social media
            provider. The profile information we receive may vary depending on
            the social media provider concerned, but will often include your
            name, email address, friends list, and profile picture, as well as
            other information you choose to make public on such a social media
            platform.
          </p>

          <p>
            We will use the information we receive only for the purposes that
            are described in this privacy notice or that are otherwise made
            clear to you on the relevant Services. Please note that we do not
            control, and are not responsible for, other uses of your personal
            information by your third-party social media provider. We recommend
            that you review their privacy notice to understand how they collect,
            use, and share your personal information, and how you can set your
            privacy preferences on their sites and apps.
          </p>

          <p>
            HOW LONG DO WE KEEP YOUR INFORMATION? In Short: We keep your
            information for as long as necessary to fulfill the purposes
            outlined in this privacy notice unless otherwise required by law.
          </p>

          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than the period of time in which users have an account with us.
          </p>

          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>

          <p>
            HOW DO WE KEEP YOUR INFORMATION SAFE? In Short: We aim to protect
            your personal information through a system of organizational and
            technical security measures.
          </p>

          <p>
            We have implemented appropriate and reasonable technical and
            organizational security measures designed to protect the security of
            any personal information we process. However, despite our safeguards
            and efforts to secure your information, no electronic transmission
            over the Internet or information storage technology can be
            guaranteed to be 100% secure, so we cannot promise or guarantee that
            hackers, cybercriminals, or other unauthorized third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your information. Although we will do our best to
            protect your personal information, transmission of personal
            information to and from our Services is at your own risk. You should
            only access the Services within a secure environment.
          </p>

          <p>
            WHAT ARE YOUR PRIVACY RIGHTS? In Short: In some regions, such as the
            European Economic Area (EEA), United Kingdom (UK), and Canada, you
            have rights that allow you greater access to and control over your
            personal information. You may review, change, or terminate your
            account at any time.
          </p>

          <p>
            In some regions (like the EEA, UK, and Canada), you have certain
            rights under applicable data protection laws. These may include the
            right (i) to request access and obtain a copy of your personal
            information, (ii) to request rectification or erasure; (iii) to
            restrict the processing of your personal information; and (iv) if
            applicable, to data portability. In certain circumstances, you may
            also have the right to object to the processing of your personal
            information. You can make such a request by contacting us by using
            the contact details provided in the section &quot;HOW CAN YOU
            CONTACT US ABOUT THIS NOTICE? (#contact) &quot; below.
          </p>

          <p>
            We will consider and act upon any request in accordance with
            applicable data protection laws.
          </p>

          <p>
            If you are located in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to your local data protection supervisory
            authority. You can find their contact details here:
            https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
          </p>

          <p>
            If you are located in Switzerland, the contact details for the data
            protection authorities are available here:
            https://www.edoeb.admin.ch/edoeb/en/home.html.
          </p>

          <p>
            Withdrawing your consent: If we are relying on your consent to
            process your personal information, which may be express and/or
            implied consent depending on the applicable law, you have the right
            to withdraw your consent at any time. You can withdraw your consent
            at any time by contacting us by using the contact details provided
            in the section &quot;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            (#contact) &quot; below.
          </p>

          <p>
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
          </p>

          <p>
            Opting out of marketing and promotional communications: You can
            unsubscribe from our marketing and promotional communications at any
            time by clicking on the unsubscribe link in the emails that we send,
            or by contacting us using the details provided in the section
            &quot;HOW CAN YOU CONTACT US ABOUT THIS NOTICE? (#contact) &quot;
            below. You will then be removed from the marketing lists. However,
            we may still communicate with you &mdash; for example, to send you
            service-related messages that are necessary for the administration
            and use of your account, to respond to service requests, or for
            other non-marketing purposes.
          </p>

          <p>Account Information</p>

          <p>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>

          <p>
            Log in to your account settings and update your user account. Upon
            your request to terminate your account, we will deactivate or delete
            your account and information from our active databases. However, we
            may retain some information in our files to prevent fraud,
            troubleshoot problems, assist with any investigations, enforce our
            legal terms and/or comply with applicable legal requirements.
          </p>

          <p>
            Cookies and similar technologies: Most Web browsers are set to
            accept cookies by default. If you prefer, you can usually choose to
            set your browser to remove cookies and to reject cookies. If you
            choose to remove cookies or reject cookies, this could affect
            certain features or services of our Services. To opt out of
            interest-based advertising by advertisers on our Services visit
            http://www.aboutads.info/choices/.
          </p>

          <p>
            If you have questions or comments about your privacy rights, you may
            email us at{" "}
            <a
              className="text-dark"
              target="_blank"
              href="mailto:autoswiperai@gmail.com"
            >
              autoswiperai@gmail.com
            </a>
          </p>

          <p>
            CONTROLS FOR DO-NOT-TRACK FEATURES Most web browsers and some mobile
            operating systems and mobile applications include a Do-Not-Track
            (&quot;DNT&quot;) feature or setting you can activate to signal your
            privacy preference not to have data about your online browsing
            activities monitored and collected. At this stage no uniform
            technology standard for recognizing and implementing DNT signals has
            been finalized. As such, we do not currently respond to DNT browser
            signals or any other mechanism that automatically communicates your
            choice not to be tracked online. If a standard for online tracking
            is adopted that we must follow in the future, we will inform you
            about that practice in a revised version of this privacy notice.
          </p>

          <p>
            DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS? In Short: Yes,
            if you are a resident of California, you are granted specific rights
            regarding access to your personal information.
          </p>

          <p>
            California Civil Code Section 1798.83, also known as the &quot;Shine
            The Light&quot; law, permits our users who are California residents
            to request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below.
          </p>

          <p>
            If you are under 18 years of age, reside in California, and have a
            registered account with Services, you have the right to request
            removal of unwanted data that you publicly post on the Services. To
            request removal of such data, please contact us using the contact
            information provided below and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Services,
            but please be aware that the data may not be completely or
            comprehensively removed from all our systems (e.g., backups, etc.).
          </p>

          <p>
            DO WE MAKE UPDATES TO THIS NOTICE? In Short: Yes, we will update
            this notice as necessary to stay compliant with relevant laws.
          </p>

          <p>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated &quot;Revised&quot; date and
            the updated version will be effective as soon as it is accessible.
            If we make material changes to this privacy notice, we may notify
            you either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>

          <p>
            HOW CAN YOU CONTACT US ABOUT THIS NOTICE? If you have questions or
            comments about this notice, you may email us at{" "}
            <a
              className="text-dark"
              target="_blank"
              href="mailto:autoswiperai@gmail.com"
            >
              autoswiperai@gmail.com
            </a>{" "}
            or by post to:
          </p>

          <p>AutoSwiper.Ai</p>

          <p>United States</p>

          <p>
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please submit a request form by
            clicking here.
          </p>

          <p>
            We use cookies to improve your experience on our site. By using our
            site, you agree to our use of cookies.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
